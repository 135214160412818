import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { sortArray } from '@fv/client-core'

import { type SortDef } from '../../components/shared/AdminTable'
import { adminFetch } from '../../utils'
import { migrateParams } from './helpers'
import { modes } from './ReportFilters'
import {
  type ReportName,
  type ReportResult,
  type ReportResultsSummary,
} from './types'

// Ensure order in queryKey used by cache
function normalizeQueryString(qs: string) {
  const source = new URLSearchParams(qs)
  const target = new URLSearchParams()

  migrateParams('range', source, target)
  migrateParams('timespan', source, target)
  migrateParams('carrierIds[]', source, target)
  migrateParams('pricingMethod', source, target)
  migrateParams('pricingType', source, target)
  migrateParams('quoteMethod', source, target)
  migrateParams('modes[]', source, target)
  migrateParams('client', source, target)
  migrateParams('includeCustomerRouted', source, target)

  return target.toString()
}

const reportsKeys = {
  all: ['reports'] as const,
  type: (qs: string, reportName?: ReportName) =>
    [...reportsKeys.all, reportName, normalizeQueryString(qs)] as const,
}

async function fetchReport(
  search: string,
  reportName?: ReportName,
): Promise<ReportResultsSummary> {
  if (!reportName) throw new Error('Report name required to fetch report.')

  const params = new URLSearchParams(search)
  return adminFetch(`/reports/${reportName}?${params.toString()}`)
}

export function useReport(
  reportName?: ReportName,
  sort?: SortDef<ReportResult>,
) {
  const { search } = useLocation()
  const { key: sortBy, dir: sortDirection }: SortDef<ReportResult> = sort ?? {
    key: 'count',
    dir: 'asc',
  }

  const select = useCallback(
    (data: ReportResultsSummary): ReportResultsSummary => {
      const isQuoteReports = reportName?.startsWith('quotes') ?? false
      const isVolumeReports = reportName?.startsWith('volume') ?? false

      if (!isQuoteReports && !isVolumeReports) return data

      let sortedResults = data.results.slice()

      if (isVolumeReports) {
        if (reportName === 'volume-by-mode') {
          let selectedModes = new URLSearchParams(search)
            .getAll('modes[]')
            .map(key => modes.find(m => m.value === key))
            .filter((m): m is { text: string; value: string } => !!m)

          if (!selectedModes.length) {
            selectedModes = modes
          }

          sortedResults = sortedResults.map(r => ({
            ...r,
            name: selectedModes.find(m => m.value === r.value)?.text ?? r.value,
          }))

          // Add missing modes to results
          selectedModes.forEach(m => {
            const hasData = !!sortedResults.find(r => r.value === m.value)

            if (!hasData) {
              sortedResults.push({
                count: 0,
                fum: 0,
                name: m.text,
                value: m.value,
              })
            }
          })
        }

        sortArray(
          sortedResults,
          [...(Array.isArray(sortBy) ? sortBy : [sortBy]), 'name', 'value'],
          sortDirection,
        )
      }

      return {
        ...data,
        results: sortedResults,
      }
    },
    [reportName, search, sortBy, sortDirection],
  )

  return useQuery(
    reportsKeys.type(search, reportName),
    () => fetchReport(search, reportName),
    {
      enabled: Boolean(reportName),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select,
      staleTime: Infinity,
    },
  )
}

async function fetchReportSettings(): Promise<{
  carriers: Array<{
    code: string
    id: string
    name: string
    status: string
  }>
}> {
  return adminFetch(`/reports/settings`)
}

export function useReportSettings() {
  return useQuery(['report-settings'], fetchReportSettings, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  })
}

import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

import { CopyButton } from './CopyButton'

type Props = PropsWithClassName<
  PropsWithChildren<{
    id?: string
    label: string
    labelAdornment?: ReactNode
    controlClass?: string
  }>
>
export const AdminFormGroup = ({
  id,
  label,
  children,
  className,
  controlClass,
  labelAdornment: labelContent,
}: Props) => {
  return (
    <div className={clsx('mb-2 flex flex-col', className)}>
      <div className="">
        <label
          className="text-xs font-semibold uppercase text-gray-500"
          htmlFor={id}
        >
          {label}
        </label>
        {labelContent}
      </div>
      <div className={clsx('flex', controlClass)}>{children}</div>
    </div>
  )
}

type CopyableProps = Props & {
  copyValue?: string
  copyMessage?: string
  copyDisabled?: boolean
}

export const CopyableFormGroup = ({
  copyValue,
  copyMessage,
  copyDisabled,
  ...props
}: CopyableProps) => {
  return (
    <AdminFormGroup
      {...props}
      labelAdornment={
        !copyDisabled && (
          <CopyButton
            className="btn-xs ml-1 border-0"
            value={copyValue || props.children?.toString()}
            successMessage={copyMessage}
          />
        )
      }
    />
  )
}

import clsx from 'clsx'
import { type ComponentPropsWithoutRef } from 'react'

export type AdminToggleProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'type'
> & {
  label?: string
}
export const AdminToggle = ({
  label,
  className,
  ...props
}: AdminToggleProps) => {
  return (
    <div className="form-control">
      <label className="label cursor-pointer justify-normal space-x-2">
        <input
          className={clsx(
            'toggle toggle-primary duration-300 ease-in-out',
            className,
          )}
          type="checkbox"
          {...props}
        />
        <span className="label-text">{label}</span>
      </label>
    </div>
  )
}

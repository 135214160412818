import { useEffect } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import { AdminSelect } from '../shared/AdminSelect'

const options = ['corporate', 'nord', 'dim', 'business']

export const ThemeSelector = () => {
  const [theme, setTheme] = useLocalStorage('adminTheme', 'corporate')

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])
  return (
    <AdminSelect
      name="theme"
      className="select-sm focus:text-neutral-content mt-0 bg-transparent pt-0"
      options={options}
      value={theme}
      onChange={e => setTheme(e.target.value)}
    />
  )
}

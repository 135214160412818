import { useMutation } from '@tanstack/react-query'

import { adminFetch } from '../utils'

async function systemReset(application: string) {
  return adminFetch(`/system/live-reset`, {
    body: { application },
    method: 'POST',
  })
}

export function useSystemReset() {
  return useMutation(systemReset)
}

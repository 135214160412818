import { useState } from 'react'
import toast from 'react-hot-toast'

import { ValidatedForm } from '@fv/client-components'
import { validateEmailInput } from '@fv/client-core'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminFormGroup } from '../../components/shared/AdminFormGroup'
import { AdminSelect } from '../../components/shared/AdminSelect'
import { AdminTextField } from '../../components/shared/AdminTextField'
import { useAddCarrierUser } from '../../hooks/useAddCarrierUser'
import { useCarriers } from '../../hooks/useCarriers'

type Props = {
  handleClose?: () => void
}

export const CarrierUserForm = (props: Props) => {
  const [carrierId, setCarrierId] = useState('')
  const [email, setUserEmail] = useState('')
  const carriers = useCarriers()
  const addUser = useAddCarrierUser()

  const handleSubmit = () => {
    addUser.mutateAsync({ carrierId, email }).then(() => {
      toast.success('User has been added to the selected carrier')
      props.handleClose()
    })
  }

  return (
    <ValidatedForm onValidSubmit={handleSubmit}>
      <div className="flex w-full flex-col md:flex-row md:gap-4">
        <AdminFormGroup label="Carriers" className="flex-1">
          <AdminSelect
            className="select-md"
            name="carrier"
            options={[
              { value: '', text: 'Select a Carrier' },
              ...carriers.map(c => ({
                text: c.name,
                value: c.id,
              })),
            ]}
            required
            onChange={e => setCarrierId(e.target.value)}
            value={carrierId ?? ''}
          />
        </AdminFormGroup>
        <AdminFormGroup label="Email" className="flex-1">
          <AdminTextField
            className="input-md"
            name="email"
            type="email"
            placeholder="Email"
            alt="email"
            value={email}
            required
            onChange={e => {
              validateEmailInput(e.target)
              setUserEmail(e.target.value)
            }}
          />
        </AdminFormGroup>
      </div>
      <div className="flex justify-end gap-2">
        <AdminButton className="btn-error" onClick={props.handleClose}>
          Cancel
        </AdminButton>
        <AdminButton className="btn-primary" type="submit">
          Save
        </AdminButton>
      </div>
    </ValidatedForm>
  )
}

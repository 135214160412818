const LoadingPage = () => {
  return (
    <div className="grid-c-portal">
      <main>
        <h1>Loading...</h1>
        <p className="lead">
          We're attempting to load the application. If you continue to see this,
          please try again later or{' '}
          <a href="mailto:support@freightview.com">contact our support team</a>.
        </p>
      </main>
    </div>
  )
}

export default LoadingPage

import { TextField, ValidatedForm } from '@fv/client-components'

import { environment } from '../../constants'
import { type AuthPageProps } from './types'

export const VerifyForm = ({
  fields,
  errors,
  onFieldChange,
  onSubmit,
}: AuthPageProps) => {
  return (
    <ValidatedForm onValidSubmit={onSubmit}>
      <legend>
        Already have a code?{' '}
        {environment !== 'production' && (
          <small>
            <br />
            <code>
              (DevMode: Validates token delivered to email and establishes a
              session).
            </code>
          </small>
        )}
      </legend>
      <div className="join">
        <TextField
          className="input input-primary"
          error={errors.token}
          maxLength={100}
          name="token"
          onChange={onFieldChange}
          placeholder="Enter your registration code."
          required
          value={fields.token}
        />
        <button className="btn btn-primary btn-outline" type="submit">
          <span>Submit</span>
        </button>
      </div>
    </ValidatedForm>
  )
}

import { useMutation } from '@tanstack/react-query'

import { carrierAppName } from '../constants'
import { adminFetch } from '../utils'

async function viewAsCarrier(userId) {
  return adminFetch(`/register/view-as`, {
    body: { userId, application: carrierAppName },
    method: 'POST',
  })
}

export function useViewAsCarrier() {
  return useMutation(viewAsCarrier)
}

import { useMutation } from '@tanstack/react-query'

import { adminFetch } from '../utils'

async function revokeSession(sessionId: string) {
  return adminFetch(`/context/${sessionId}`, { method: 'DELETE' })
}

export function useRevokeSession() {
  return useMutation(revokeSession)
}

import { useMutation } from '@tanstack/react-query'

import { showApiError } from '@fv/client-core'

import { adminFetch } from '../utils'

const addUser = (CarrierUser: { carrierId: string; email: string }) => {
  const { carrierId, ...body } = CarrierUser
  return adminFetch(`/carriers/${carrierId}/user`, {
    body,
    method: 'POST',
  })
}
export const useAddCarrierUser = () => {
  return useMutation(addUser, {
    onError: e => {
      showApiError('Error adding carrier user', e)
    },
  })
}

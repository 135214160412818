import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import { type UpsertSystemNotificationDTO } from '@fv/models'

import { adminFetch } from '../../utils'
import { systemNotificationQueryKeys } from './queries'

const postNotification = (body: UpsertSystemNotificationDTO) => {
  return adminFetch('/system-notifications', {
    method: 'POST',
    body,
  })
}
export const useCreateNotification = () => {
  const queryClient = useQueryClient()
  return useMutation(postNotification, {
    onSettled(data, err) {
      if (err) {
        showApiError('Failed to create system notification', err)
        return
      }
      toast.success('Notification created')
      queryClient.invalidateQueries(systemNotificationQueryKeys.all)
    },
  })
}

const putNotification = ({
  id,
  body,
}: {
  id: string
  body: UpsertSystemNotificationDTO
}) => {
  return adminFetch(`/system-notifications/${id}`, {
    method: 'PUT',
    body,
  })
}
export const useUpdateNotification = () => {
  const queryClient = useQueryClient()
  return useMutation(putNotification, {
    onSettled(data, err) {
      if (err) {
        showApiError('Failed to update system notification', err)
        return
      }
      toast.success('Notification updated')
      queryClient.invalidateQueries(systemNotificationQueryKeys.all)
    },
  })
}

export const useDeleteNotification = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (id: string) =>
      adminFetch(`/system-notifications/${id}`, { method: 'DELETE' }),
    {
      onSettled(data, err) {
        if (err) {
          showApiError('Failed to delete system notification', err)
          return
        }
        toast.success('Notification deleted')
        queryClient.invalidateQueries(systemNotificationQueryKeys.all)
      },
    },
  )
}

export const sanitizeForSort = (val: any): any => {
  return typeof val === 'string' ? val.toLowerCase().trim() : val
}

export function sortArray<T extends Record<string, any>>(
  objects: T[],
  keys: keyof T | Array<keyof T>,
  direction: 'asc' | 'desc',
): T[] {
  const arrayKeys = Array.isArray(keys) ? keys : [keys]

  return objects.sort((a, b) => {
    for (const key of arrayKeys) {
      const valueA = sanitizeForSort(a[key])
      const valueB = sanitizeForSort(b[key])

      if (valueA < valueB) {
        return direction === 'asc' ? -1 : 1
      }

      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1
      }
    }
    return 0
  })
}

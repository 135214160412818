import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Icon, useFvNavigate } from '@fv/client-components'
import {
  defaultOptions,
  fetchJson,
  redirectToApp,
  setToken,
} from '@fv/client-core'

import { apiUri } from '../../constants'
import { AdminCard } from '../shared/AdminCard'
import { LoginMain } from './LoginMain'
import { VerifyPage } from './VerifyPage'

export const Login = () => {
  const { registrationToken } = useParams()
  const navigate = useFvNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!registrationToken) return

    async function establishSession() {
      const options = defaultOptions({
        cache: 'no-store',
        json: { token: registrationToken },
        method: 'POST',
      })

      const result = await fetchJson(`${apiUri}/register/verify`, options)

      if (result.ok && result.json?.token) {
        setToken(result.json.token)
        redirectToApp({ location })
      } else {
        navigate('/login')
      }
    }

    establishSession().catch(err => {
      console.error(err)
      navigate('/login')
    })
  }, [registrationToken, navigate, location])

  if (registrationToken) {
    return (
      <h1>
        <Icon icon="spinner" />
      </h1>
    )
  }

  return (
    <div
      id="public"
      className="container mx-auto flex max-w-md flex-col items-center justify-center pt-12"
    >
      <AdminCard title="Welcome back!" className="shadow-lg">
        <LoginMain />
        <VerifyPage />
      </AdminCard>
    </div>
  )
}

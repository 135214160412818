import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

import { getToken, logoutSession, redirectToLogin } from '@fv/client-core'

import { apiUri } from '../constants'
import { adminFetch } from '../utils'

type ContextDTO = {
  liveEventsUri?: string
  sessionId: string
}

async function fetchAdminContext() {
  return adminFetch<ContextDTO>('/context')
}

export function useAdminContext() {
  const token = getToken()

  useEffect(() => {
    if (!token) redirectToLogin()
  }, [token])

  return useQuery(['adminContext'], fetchAdminContext, {
    enabled: Boolean(token),
    onError: (e: any) => {
      if (e?.status === 401) {
        logoutSession(null, apiUri)
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    retry: false,
  })
}

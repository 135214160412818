import PropTypes from 'prop-types'
import { createContext } from 'react'

import { logoutSession } from '@fv/client-core'

import LoadingPage from '../components/layout/LoadingPage'
import { apiUri } from '../constants'
import { useAdminContext } from '../hooks/useAdminContext'

export const siteContext = createContext()

// Here we will manage site-wide data like user context and active opportunities.
const SiteProvider = ({ children }) => {
  const contextQuery = useAdminContext()

  async function logout(id) {
    await logoutSession(id, apiUri)
  }

  return (
    <siteContext.Provider value={{ logout }}>
      {contextQuery.data ? children : <LoadingPage />}
    </siteContext.Provider>
  )
}

SiteProvider.propTypes = {
  children: PropTypes.any,
}

export default SiteProvider

import { useEffect, useRef } from 'react'

import { TextField, ValidatedForm } from '@fv/client-components'

import { environment } from '../../constants'
import { AdminButton } from '../shared/AdminButton'
import { type AuthPageProps } from './types'

export const LoginForm = ({
  fields,
  errors,
  onFieldChange,
  onSubmit,
}: AuthPageProps) => {
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    refInput.current?.focus()
  }, [])

  return (
    <ValidatedForm onValidSubmit={onSubmit}>
      <legend>
        Register for access{' '}
        {environment !== 'production' && (
          <small>
            <br />
            <code>
              (DevMode: Only sets up registration token and emails it to user).
            </code>
          </small>
        )}
      </legend>
      <div className="join">
        <TextField
          autoFocus
          className="input input-primary"
          error={errors.email}
          maxLength={200}
          name="email"
          onChange={onFieldChange}
          placeholder="Enter your email"
          ref={refInput}
          required
          type="email"
          value={fields.email}
        />
        <AdminButton type="submit" className="btn-primary">
          Submit
        </AdminButton>
      </div>
    </ValidatedForm>
  )
}

import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type CardProps = {
  title?: string
}
export const AdminCard = ({
  children,
  className,
  title,
}: PropsWithChildren<PropsWithClassName<CardProps>>) => {
  return (
    <div className={clsx('card', className)}>
      <div className="card-body">
        {title && <div className="card-title">{title}</div>}
        {children}
      </div>
    </div>
  )
}

import {
  AdminToggle,
  type AdminToggleProps,
} from '../../components/shared/AdminToggle'

type Props = AdminToggleProps
export const ReportFilterToggle = ({ label, ...props }: Props) => {
  return (
    <div className="space-between flex items-center">
      <label htmlFor={props.id ?? props.name}>{label}</label>
      <div className="border-primary mx-2 flex-grow border-b" />
      <AdminToggle id={props.id ?? props.name} {...props} />
    </div>
  )
}

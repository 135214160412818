import { useQuery } from '@tanstack/react-query'

import { type SystemNotification } from '@fv/models'
import { type SerializedModel } from '@fv/models/core'

import { adminFetch } from '../../utils'

export const systemNotificationQueryKeys = {
  all: ['system-notifications'],
}

const fetchSystemNotes = () => {
  return adminFetch<Array<SerializedModel<SystemNotification>>>(
    '/system-notifications',
  )
}
export const useSystemNotifications = () => {
  return useQuery(systemNotificationQueryKeys.all, fetchSystemNotes)
}

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'react-hot-toast'

import SiteProvider from '../providers/SiteProvider'
import { Nav } from './layout/Nav'
import { AdminRoutes } from './AdminRoutes'

const queryClient = new QueryClient()

export const AuthenticatedApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <SiteProvider>
        <Nav />
        <main role="main" className="mt-16 flex flex-grow flex-col">
          <AdminRoutes />
        </main>
      </SiteProvider>

      <ReactQueryDevtools initialIsOpen={false} position="top-left" />
      <Toaster position="bottom-center" />
    </QueryClientProvider>
  )
}

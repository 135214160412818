import clsx from 'clsx'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { Icon } from '@fv/client-components'

import { carrierUri } from '../../constants'
import SessionList from './SessionList'
import { ViewAsLink } from './ViewAsLink'

const UserRow = ({ user, carriers, revokeSession }) => {
  const carrier =
    user.carrierId && carriers?.length
      ? carriers.find(c => c._id === user.carrierId)
      : null

  const accounts = user.accounts

  return (
    <tr>
      <td>
        {carrier ? (
          <>
            {carrier.name}
            {carrier.code && (
              <span className="badge badge-sm badge-outline">
                {carrier.code}
              </span>
            )}
          </>
        ) : (
          '--'
        )}
      </td>
      <td>
        {user.email}&nbsp;
        <span
          className={clsx('badge badge-sm badge-outline', {
            'badge-success': user.isVerified,
          })}
        >
          <Icon icon={user.isVerified ? 'check-circle' : 'times'} />
          &nbsp;
          {user.isVerified ? ' verified' : ' unverified'}
        </span>
        <br />
        <small>
          <code>id: {user._id}</code>
        </small>
        {user.activeRegistration && (
          <>
            <br />
            <strong>Login link:</strong>
            <br />
            <span className="badge badge-danger">
              <code className="text-light">
                {carrierUri}/login/{user.activeRegistration.token}
              </code>
            </span>{' '}
            <em>
              (expires {dayjs(user.activeRegistration.expires).fromNow()})
            </em>
          </>
        )}
      </td>
      <td>
        {accounts?.length ? (
          <>
            {accounts.map(a => (
              <>
                {a} <br />
              </>
            ))}
          </>
        ) : (
          '--'
        )}
      </td>
      <td>
        <SessionList sessions={user.sessions} revoke={revokeSession} />
      </td>
      <td>
        <ViewAsLink userId={user._id} />
      </td>
    </tr>
  )
}

UserRow.propTypes = {
  carriers: PropTypes.array,
  revokeSession: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  accounts: PropTypes.array,
}

export default UserRow

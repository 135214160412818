import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react'

import { AdminButton } from '../../components/shared/AdminButton'
import { AdminSelect } from '../../components/shared/AdminSelect'
import { type ReportFilterParam } from './types'

type MultiSelectFilterProps = {
  name: ReportFilterParam
  options: Array<{ text: string; value: string }>
  setQueryString: Dispatch<SetStateAction<string>>
  value: string[]
}
export const ReportMultiSelectFilter = ({
  name,
  options,
  setQueryString,
  value,
}: MultiSelectFilterProps) => (
  <>
    {value.map((v, i) => {
      const fieldName = `${name}_${i}`
      const fieldOptions = options.filter(o => !value.includes(o.value))
      const isLastRow = i === value.length - 1

      const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setQueryString(qs => {
          const params = new URLSearchParams(qs)
          params.delete(name)

          const nextValues = value.slice()
          nextValues[i] = e.target.value
          nextValues.forEach(v => params.append(name, v))

          return params.toString()
        })
      }

      const addRow = () => {
        setQueryString(qs => {
          const params = new URLSearchParams(qs)
          params.append(name, fieldOptions[0].value)

          return params.toString()
        })
      }

      const removeRow = () => {
        setQueryString(qs => {
          const params = new URLSearchParams(qs)
          params.delete(name)

          const nextValues = value.filter(o => o !== v)
          nextValues.forEach(v => params.append(name, v))

          return params.toString()
        })
      }

      return (
        <MultiFilterRow
          addRow={addRow}
          canAdd={isLastRow && fieldOptions.length > 0}
          canRemove={value.length > 1}
          isLastRow={isLastRow}
          key={fieldName}
          name={fieldName}
          onChange={onChange}
          options={[options.find(o => o.value === v)]
            .concat(fieldOptions)
            .filter((o): o is { text: string; value: string } => !!o)}
          removeRow={removeRow}
          value={v}
        />
      )
    })}
  </>
)

type MultiFilterRowProps = {
  addRow: () => void
  canAdd: boolean
  canRemove: boolean
  isLastRow: boolean
  name: string
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  options: Array<{ text: string; value: string }>
  removeRow: () => void
  value: string
}
const MultiFilterRow = ({
  addRow,
  canAdd,
  canRemove,
  name,
  onChange,
  options,
  removeRow,
  value,
}: MultiFilterRowProps) => {
  return (
    <div className="mb-2 flex">
      <AdminSelect
        className="max-w-[80%] flex-grow"
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
      &nbsp;
      {canAdd && (
        <AdminButton
          icon="plus"
          onClick={() => {
            addRow()
          }}
        />
      )}
      {!canAdd && canRemove && (
        <AdminButton
          icon={'times'}
          onClick={e => {
            e.preventDefault()
            removeRow()
          }}
        />
      )}
    </div>
  )
}

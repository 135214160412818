import { copyToClipboard } from '@fv/client-core'

import { AdminButton, type AdminButtonProps } from './AdminButton'

type CopyButtonProps = AdminButtonProps & {
  value?: string
  successMessage?: string
}

export const CopyButton = ({
  children,
  value,
  successMessage,
  ...props
}: CopyButtonProps) => {
  if (!value) {
    return null
  }

  return (
    <AdminButton
      icon="clipboard"
      {...props}
      onClick={() => copyToClipboard(value, successMessage)}
    >
      {children}
    </AdminButton>
  )
}

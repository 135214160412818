import toast from 'react-hot-toast'

import { downloadCSV } from '@fv/client-core'

export const NO_EXPORT_ATTR = 'data-no-export'

export function composePath(
  report: string,
  params: { [key: string]: string | undefined },
  reportType: 'fv' | 'engine' = 'fv',
) {
  const query = new URLSearchParams()
  const root = reportType === 'fv' ? 'reports' : 'engine-reports'

  Object.keys(params).forEach(k => {
    const value = params[k]
    if (value) query.set(k, value)
  })

  return `/${root}/${report}?${query.toString()}`
}

// https://www.geeksforgeeks.org/how-to-export-html-table-to-csv-using-javascript
export function downloadReport(reportName: string): void {
  const csvData = []
  const rows = document.getElementsByTagName('tr')

  if (!rows?.length) {
    toast.error('No data available.')
    return
  }

  for (let i = 0; i < rows.length; i++) {
    const columns = rows[i].querySelectorAll('td,th')
    const csvRow = []

    for (let j = 0; j < columns.length; j++) {
      const clone = columns[j].cloneNode(true) as HTMLElement
      clone.querySelectorAll(`[${NO_EXPORT_ATTR}]`)?.forEach(n => n.remove())

      csvRow.push(clone.textContent?.replace(/,/g, ''))
    }

    csvData.push(csvRow.join(','))
  }

  downloadCSV(csvData, reportName)
  toast.success('Report downloaded successfully.')
}

export function formatNumber(number?: number) {
  if (!number) return number

  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: number % 1 ? 2 : 0,
    minimumFractionDigits: number % 1 ? 2 : 0,
  }).format(number)
}

export function migrateParams(
  key: string,
  source: URLSearchParams,
  target: URLSearchParams,
) {
  if (key.slice(-2) === '[]') {
    source
      .getAll(key)
      .sort((a, b) => {
        const aVal = a.toLocaleLowerCase()
        const bVal = b.toLocaleLowerCase()
        return aVal.localeCompare(bVal)
      })
      .forEach(v => {
        target.append(key, v)
      })
  } else {
    const value = source.get(key)
    value && target.set(key, value)
  }
}

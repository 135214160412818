import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { useFvNavigate } from '@fv/client-components'
import { formatFeatureText } from '@fv/client-core'
import { type ProductType, productTypes } from '@fv/models'

import { AdminButton } from '../../components/shared/AdminButton'
import { useCarriers } from '../../hooks/useCarriers'
import { ReportFilterSelect } from './ReportFilterSelect'
import { ReportFilterToggle } from './ReportFilterToggle'
import { ReportMultiSelectFilter } from './ReportMultiSelectFilter'
import { type ReportFilterParam, timespans } from './types'

type TimespanFilterProps = {
  setQueryString: Dispatch<SetStateAction<string>>
  value: string | null
  showHourly?: boolean
}

const TimespanFilter = ({
  setQueryString,
  value,
  showHourly,
}: TimespanFilterProps) => {
  const spans = timespans.filter(t => showHourly || t !== 'hourly')

  return (
    <div className="filter-type">
      <ReportFilterToggle
        label="Interval"
        checked
        disabled
        name="check_timespan"
        onChange={() => null}
      />

      <ReportFilterSelect
        name="timespan"
        options={spans.map(s => ({ text: s, value: s }))}
        setQueryString={setQueryString}
        value={value ?? ''}
      />
    </div>
  )
}

type CarrierFilterProps = {
  setQueryString: Dispatch<SetStateAction<string>>
  toggleCheckbox: (
    name: ReportFilterParam,
    value: string,
  ) => (e: ChangeEvent<HTMLInputElement>) => void
  value: string[]
}

const carrierId = 'carrierIds[]'

const CarrierFilter = ({
  setQueryString,
  toggleCheckbox,
  value,
}: CarrierFilterProps) => {
  const carriers = useCarriers()
  const checked = value.length > 0
  const options = useMemo(
    () => carriers.map(c => ({ text: c.name, value: c.id })) ?? [],
    [carriers],
  )

  return (
    <div className="filter-type">
      <ReportFilterToggle
        label="Carrier"
        checked={checked}
        name="check_carrier"
        onChange={toggleCheckbox(carrierId, options[0]?.value ?? '')}
      />

      {checked && (
        <ReportMultiSelectFilter
          name={carrierId}
          options={options}
          setQueryString={setQueryString}
          value={value}
        />
      )}
    </div>
  )
}

const mode = 'modes[]'
export const modes = [
  { text: 'Air', value: 'air' },
  { text: 'Bulk', value: 'bulk' },
  { text: 'Consolidated', value: 'consolidated' },
  { text: 'Intermodal', value: 'intermodal' },
  { text: 'Less than truckload', value: 'ltl' },
  { text: 'Ocean', value: 'ocean' },
  { text: 'Parcel', value: 'parcel' },
  { text: 'Truckload', value: 'truckload' },
]

const pricingMethod = 'pricingMethod'
const pricingMethods = [
  { text: 'Capacity', value: 'capacity' },
  { text: 'Contracted', value: 'contracted' },
]

const pricingType = 'pricingType'
const pricingTypes = [
  { text: 'Density', value: 'density' },
  { text: 'Dimensional', value: 'dimensional' },
  { text: 'Pallet', value: 'pallet' },
  { text: 'Spot', value: 'spot' },
  { text: 'Tariff', value: 'tariff' },
]

const quoteMethod = 'quoteMethod'
const quoteMethods = [
  { text: 'API', value: 'api' },
  { text: 'Email', value: 'email' },
  { text: 'Manual', value: 'manual' },
  { text: 'Upload', value: 'upload' },
]

const product = 'product[]'
const products: Array<{ text: string; value: ProductType }> = productTypes.map(
  feature => ({
    text: formatFeatureText(feature),
    value: feature,
  }),
)

const client = 'client'
const clients = [
  { text: 'Freightview', value: 'freightview' },
  { text: 'C.H. Robinson', value: 'chr' },
]

type ReportFiltersProps = { filters?: ReportFilterParam[] }

const ReportFilters = ({ filters }: ReportFiltersProps) => {
  const { pathname, search } = useLocation()
  const navigate = useFvNavigate()
  const [qs, setQueryString] = useState('')
  const params = new URLSearchParams(qs)

  useEffect(() => {
    setQueryString(new URLSearchParams(search).toString())
  }, [search])

  const toggleCheckbox =
    (name: ReportFilterParam, value: string) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setQueryString(qs => {
        const checked = e.target.checked
        const params = new URLSearchParams(qs)

        if (checked) params.append(name, value)
        else params.delete(name)

        return params.toString()
      })
    }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="bg-secondary border-primary flex-grow border-b bg-opacity-20 px-4">
        {!filters && (
          <div className="filter-type">
            <div className="flex !flex-nowrap">
              No additional filters available
            </div>
          </div>
        )}

        {filters?.some(f => f.startsWith('timespan')) && (
          <TimespanFilter
            setQueryString={setQueryString}
            showHourly={!filters.includes('timespan-no-hour')}
            value={params.get('timespan')}
          />
        )}

        {filters?.includes(carrierId) && (
          <CarrierFilter
            setQueryString={setQueryString}
            toggleCheckbox={toggleCheckbox}
            value={params.getAll(carrierId)}
          />
        )}

        {filters?.includes(pricingMethod) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Pricing method"
              checked={!!params.get(pricingMethod)}
              name="check_pricingMethod"
              onChange={toggleCheckbox(pricingMethod, 'capacity')}
            />

            {!!params.get(pricingMethod) && (
              <ReportFilterSelect
                name={pricingMethod}
                options={pricingMethods}
                setQueryString={setQueryString}
                value={params.get(pricingMethod) ?? ''}
              />
            )}
          </div>
        )}

        {filters?.includes(pricingType) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Pricing type"
              checked={!!params.get(pricingType)}
              name="check_pricingType"
              onChange={toggleCheckbox(pricingType, 'density')}
            />

            {!!params.get(pricingType) && (
              <ReportFilterSelect
                name={pricingType}
                options={pricingTypes}
                setQueryString={setQueryString}
                value={params.get(pricingType) ?? ''}
              />
            )}
          </div>
        )}

        {filters?.includes(mode) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Shipping mode"
              checked={params.getAll(mode).length > 0}
              name="check_mode"
              onChange={toggleCheckbox(mode, 'air')}
            />

            {params.getAll(mode).length > 0 && (
              <ReportMultiSelectFilter
                name={mode}
                options={modes}
                setQueryString={setQueryString}
                value={params.getAll(mode)}
              />
            )}
          </div>
        )}

        {filters?.includes(quoteMethod) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Quote method"
              checked={!!params.get(quoteMethod)}
              name="check_quoteMethod"
              onChange={toggleCheckbox(quoteMethod, 'api')}
            />

            {!!params.get(quoteMethod) && (
              <ReportFilterSelect
                name={quoteMethod}
                options={quoteMethods}
                setQueryString={setQueryString}
                value={params.get(quoteMethod) ?? ''}
              />
            )}
          </div>
        )}

        {filters?.includes(product) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Products on Account"
              checked={!!params.get(product)}
              name="check_productsOnAccount"
              onChange={toggleCheckbox(product, 'fuelAndDistance')}
            />

            {params.getAll(product).length > 0 && (
              <ReportMultiSelectFilter
                name={product}
                options={products}
                setQueryString={setQueryString}
                value={params.getAll(product)}
              />
            )}
          </div>
        )}

        {filters?.includes(client) && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Client"
              checked={!!params.get(client)}
              name="check_client"
              onChange={toggleCheckbox(client, 'freightview')}
            />

            {params.getAll(client).length > 0 && (
              <ReportFilterSelect
                name={client}
                options={clients}
                setQueryString={setQueryString}
                value={params.get(client) ?? ''}
              />
            )}
          </div>
        )}

        {filters?.includes('includeCustomerRouted') && (
          <div className="filter-type">
            <ReportFilterToggle
              label="Include Customer routed"
              checked={!!params.get('includeCustomerRouted')}
              name="check_isFreightCollect"
              onChange={toggleCheckbox('includeCustomerRouted', 'true')}
            />
          </div>
        )}
      </div>

      {!!filters && (
        <div className="flex h-20 items-center justify-center">
          <div>
            <AdminButton
              icon="filter"
              className="btn-primary"
              onClick={() => navigate(`${pathname}?${qs}`)}
            >
              Filter
            </AdminButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportFilters

import clsx from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'

import { SelectField, type SelectFieldProps } from '@fv/client-components'

type Props = SelectFieldProps
export const AdminSelect = forwardRef(
  ({ className, ...props }: Props, ref: ForwardedRef<HTMLSelectElement>) => (
    <SelectField
      className={clsx('select select-bordered', className)}
      ref={ref}
      {...props}
    />
  ),
)

import { useEffect, useState } from 'react'

import { Icon } from '@fv/client-components'

import { AdminSliderPanel } from '../../components/AdminSliderPanel'
import { AdminButton } from '../../components/shared/AdminButton'
import { AdminCard } from '../../components/shared/AdminCard'
import { useFetchUsers } from '../../hooks/useFetchUsers'
import { useRevokeSession } from '../../hooks/useRevokeSession'
import { CarrierUserForm } from './CarrierUserForm'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'

const Search = ({ initQuery }) => {
  const [errors, setErrors] = useState({})
  const [fields, setFields] = useState({
    search: initQuery?.trim() ?? '',
    whole_word: false,
  })
  const [showAddUser, setShowAddUser] = useState(false)

  const revokeSession = useRevokeSession()
  const usersQuery = useFetchUsers(fields)

  useEffect(() => {
    if (!usersQuery.error) return
    setErrors({
      search: usersQuery.error.errors[0]?.message || usersQuery.error.message,
    })
  }, [usersQuery.error])

  const handleClose = () => {
    setShowAddUser(false)
  }

  return (
    <>
      <AdminCard title="Search Carrier Users" className="shadow-md">
        <div className="flex items-center">
          <AdminButton
            type="button"
            theme="default"
            icon="plus"
            className="btn-sm"
            onClick={() => setShowAddUser(prev => !prev)}
          >
            Add user
          </AdminButton>
        </div>

        <SearchForm errors={errors} fields={fields} setFields={setFields} />
        <p className="text-xs italic">
          NOTE: This searches both carriers and users independently.
        </p>
        {usersQuery.isFetching && (
          <div className="!flex !justify-center !pt-8">
            <Icon icon="spinner" size="2x" />
          </div>
        )}

        {usersQuery.data && !usersQuery.isFetching && (
          <SearchResults
            results={usersQuery.data}
            revokeSession={sessionId => {
              revokeSession.mutateAsync(sessionId).then(() => {
                usersQuery.refetch()
              })
            }}
          />
        )}
      </AdminCard>

      <AdminSliderPanel isOpen={showAddUser} closePanel={handleClose}>
        <CarrierUserForm handleClose={handleClose} />
      </AdminSliderPanel>
    </>
  )
}

export default Search

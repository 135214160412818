import { type ChangeEvent, useState } from 'react'

import { defaultOptions, fetchJson } from '@fv/client-core'

import { apiUri, appName } from '../../constants'
import { LoginForm } from './LoginForm'
import Thanks from './Thanks'

export const LoginMain = () => {
  const [fields, setFields] = useState({ email: '' })
  const [errors, setErrors] = useState({})
  const [showForm, setShowForm] = useState(true)
  const [message, setMessage] = useState()

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const shallowClone = { ...fields }
    shallowClone.email = value
    setFields(shallowClone)
  }

  function onSubmit() {
    register().catch(err => {
      setMessage(err.toString())
    })
  }

  async function register() {
    setErrors({})
    const args = defaultOptions({
      method: 'POST',
      cache: 'no-store',
      json: { email: fields.email, application: appName },
    })
    const result = await fetchJson(`${apiUri}/register`, args)

    if (!result.ok) return setMessage(result.errorMessage)
    if (result.status === 400) return setErrors(result.json?.errors || {})
    setShowForm(false)
  }

  return (
    <div className="my-5">
      {message && (
        <div className="alert alert-danger">
          <code>{message}</code>
        </div>
      )}{' '}
      {showForm ? (
        <LoginForm
          onFieldChange={onFieldChange}
          fields={fields}
          errors={errors}
          onSubmit={onSubmit}
        />
      ) : (
        <Thanks />
      )}
    </div>
  )
}

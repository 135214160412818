import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  type LiveEvent,
  useLiveEventsProcessor,
  useNavState,
} from '@fv/client-components'

import { apiUri } from '../constants'
import { AdminHomePage } from '../features/home/AdminHomePage'
import { useAdminContext } from '../hooks/useAdminContext'
import NotFoundPage from './layout/NotFoundPage'

const LoadListingPage = lazy(
  async () => import('../features/loads/LoadListingPage'),
)
const DispatchesPage = lazy(
  () => import('../features/dispatches/DispatchesPage'),
)
const AccountRoutes = lazy(
  () => import('../features/accounts/AdminAccountRoutes'),
)
const ReportsPage = lazy(() => import('../features/reports/ReportsPage'))
const EngineReportsPage = lazy(
  () => import('../features/reports/EngineReportsPage'),
)
const ProvisioningPage = lazy(
  () => import('../features/provisioning/ProvisioningPage'),
)

export const AdminRoutes = () => {
  const contextQuery = useAdminContext()
  const notifyRefresh = useNavState(s => s.notifyRefresh)

  function handleEvent(event: LiveEvent) {
    const eventName = event.event

    if (eventName === 'system:reset') {
      notifyRefresh()
    }
  }
  useLiveEventsProcessor({
    authenticateUri: `${apiUri}/live-events`,
    handleEvent,
    liveEventsUri: contextQuery.data?.liveEventsUri,
  })

  return (
    <Routes>
      <Route path="search/:query" element={<AdminHomePage />} />
      <Route path="/" element={<AdminHomePage />} />
      <Route
        path="dispatches"
        element={
          <Suspense>
            <DispatchesPage />
          </Suspense>
        }
      />

      <Route
        path="loads"
        element={
          <Suspense>
            <LoadListingPage />
          </Suspense>
        }
      >
        <Route path=":loadId" element={null} />
      </Route>

      <Route
        path="accounts/*"
        element={
          <Suspense>
            <AccountRoutes />
          </Suspense>
        }
      />
      <Route
        path="reports"
        element={
          <Suspense>
            <ReportsPage />
          </Suspense>
        }
      >
        <Route path=":reportName" element={null} />
      </Route>

      <Route
        path="engine-reports"
        element={
          <Suspense>
            <EngineReportsPage />
          </Suspense>
        }
      >
        <Route path=":reportName" element={null} />
      </Route>
      <Route
        path="provisioning"
        element={
          <Suspense>
            <ProvisioningPage />
          </Suspense>
        }
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

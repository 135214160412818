import clsx from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type ForwardedRef,
  forwardRef,
} from 'react'

import { type FvButtonProps, Icon, Tooltip } from '@fv/client-components'

export type AdminButtonProps = ComponentPropsWithoutRef<'button'> &
  Pick<FvButtonProps, 'icon' | 'loading'> & {
    noOutline?: boolean
    tooltip?: string
  }
export const AdminButton = forwardRef(
  (
    {
      children,
      className,
      tooltip,
      noOutline,
      loading,
      icon,
      ...props
    }: AdminButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Tooltip label={tooltip}>
      <button
        className={clsx('btn flex gap-2 uppercase', className, {
          'btn-outline': !noOutline,
        })}
        type={props.type ?? 'button'}
        {...props}
        ref={ref}
      >
        {loading && <Icon icon="spinner" />}
        {icon && !loading && <Icon icon={icon} />}
        {children}
      </button>
    </Tooltip>
  ),
)

import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { Icon } from '@fv/client-components'

import { AdminButton } from '../../components/shared/AdminButton'

const SessionList = ({ sessions, revoke }) => {
  if (!sessions?.length) return null

  return (
    <dl>
      <dt>Expires</dt>
      {sessions.map((s, sx) => (
        <dd key={sx}>
          <Icon
            className={s.adminUserId ? 'text-error' : 'text-info'}
            icon={s.adminUserId ? 'user-shield' : 'user'}
            title={s.adminUserId ? 'This is an admin session' : ''}
          />
          &nbsp;
          <code>
            {dayjs(s.expires).utc().format('YYYY-MM-DD h:mm A')}
            &nbsp;UTC&nbsp;(
            {dayjs(s.expires).fromNow()})
          </code>
          &nbsp;
          <AdminButton
            className="btn-outline btn-error btn-xs"
            onClick={() => {
              revoke(s._id)
            }}
          >
            revoke
          </AdminButton>
          <br />
        </dd>
      ))}
    </dl>
  )
}

SessionList.propTypes = {
  revoke: PropTypes.func.isRequired,
  sessions: PropTypes.array,
}

export default SessionList

import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { Icon } from '@fv/client-components'

import { AdminCard } from '../../components/shared/AdminCard'
import { siteTitle } from '../../constants'
import { useSystemReset } from '../../hooks/useSystemReset'
import Search from '../carrier-users/Search'
import { NotificationsCard } from '../notifications/NotificationsCard'

const clients = ['carrier-web', 'admin-web', 'shipper-web'] as const
type ClientType = (typeof clients)[number]

const mapClientName = (type: ClientType) => {
  switch (type) {
    case 'carrier-web':
      return 'Carrier Portal'
    case 'admin-web':
      return 'Admin Website'
    case 'shipper-web':
      return 'Shipper App'
  }
}

const CarrierPortalAdminControls = () => {
  const systemReset = useSystemReset()

  async function resetClients(application: ClientType) {
    systemReset
      .mutateAsync(application)
      .then(() => {
        toast.success(
          `${mapClientName(application)} clients have been notified`,
          {
            duration: 10000,
          },
        )
      })
      .catch(e => {
        alert(e?.message)
      })
  }

  function onClientResetClicked(application: ClientType) {
    const appName = mapClientName(application)

    if (
      appName &&
      window.confirm(
        `WARNING! This will trigger a browser refresh on ALL ${appName} clients. Are you sure?`,
      )
    ) {
      resetClients(application)
    }
  }

  return (
    <AdminCard title="Admin Controls" className="mx-auto shadow-md">
      <div className="flex space-x-2">
        {clients.map(c => (
          <button
            key={c}
            type="button"
            className="btn btn-outline"
            onClick={() => onClientResetClicked(c)}
            data-application="carrier-web"
          >
            <Icon icon="exclamation-triangle" className="text-warning" />
            &nbsp; Reset {mapClientName(c)}
          </button>
        ))}
      </div>
    </AdminCard>
  )
}

export const AdminHomePage = () => {
  const params = useParams()
  useEffect(() => {
    document.title = `Admin Portal - ${siteTitle}`
  }, [])

  return (
    <main role="main" className="container mx-auto p-3">
      <CarrierPortalAdminControls />
      <br />
      <Search initQuery={params.query} />
      <br />
      <NotificationsCard />
    </main>
  )
}

import PropTypes from 'prop-types'

import CarrierResults from './CarrierResults'
import UserResults from './UserResults'

const SearchResults = ({ results, revokeSession }) => {
  if (!results) return <h3 className="mt-3">No results found.</h3>

  return (
    <>
      <div className="mt-3">
        <h3>Matching carriers</h3>

        <CarrierResults carriers={results.carriers} />
      </div>
      <div className="mt-3">
        <h3>Matching Users</h3>

        <UserResults
          users={results.users}
          carriers={results.carriers}
          revokeSession={revokeSession}
        />
      </div>
    </>
  )
}

SearchResults.propTypes = {
  results: PropTypes.object.isRequired,
  revokeSession: PropTypes.func.isRequired,
}

export default SearchResults

import { type ChangeEvent, type Dispatch, type SetStateAction } from 'react'

import { AdminSelect } from '../../components/shared/AdminSelect'
import { type ReportFilterParam } from './types'

type ReportFilterSelectProps = {
  name: ReportFilterParam
  options: Array<{ text: string; value: string }>
  setQueryString?: Dispatch<SetStateAction<string>>
  onSelect?: (value: string) => void
  value: string
}

export const ReportFilterSelect = ({
  name,
  onSelect,
  options,
  setQueryString,
  value,
}: ReportFilterSelectProps) => {
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onSelect?.(e.target.value)
    setQueryString?.(qs => {
      const value = e.target.value
      const params = new URLSearchParams(qs)
      params.set(name, value)
      return params.toString()
    })
  }

  return (
    <div className="filter-type__options">
      <AdminSelect
        className="w-full"
        name={name}
        onChange={onChange}
        options={options}
        value={value}
      />
    </div>
  )
}

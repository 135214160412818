import { type ChangeEvent, useState } from 'react'

import {
  defaultOptions,
  fetchJson,
  redirectToApp,
  setToken,
} from '@fv/client-core'

import { apiUri } from '../../constants'
import { VerifyForm } from './VerifyForm'

export const VerifyPage = () => {
  const [fields, setFields] = useState({ token: '' })
  const [errors, setErrors] = useState({})
  const [message, setMessage] = useState()

  function onFieldChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    const shallowClone = { ...fields }
    shallowClone.token = value
    setFields(shallowClone)
  }

  function onSubmit() {
    verifyRegistration().catch(err => {
      setMessage(err.message)
    })
  }

  async function verifyRegistration() {
    setErrors({})
    const args = defaultOptions({
      method: 'POST',
      cache: 'no-store',
      json: { token: fields.token },
    })
    const result = await fetchJson(`${apiUri}/register/verify`, args)

    if (result.status === 400) setErrors(result.json?.errors || {})

    if (result.ok) {
      const token = result.json?.token
      setToken(token)
      redirectToApp()
    } else setMessage(result.errorMessage)
  }

  return (
    <div className="form-signin">
      {message && (
        <div className="alert alert-danger">
          <code>{message}</code>
        </div>
      )}{' '}
      <VerifyForm
        fields={fields}
        errors={errors}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
      />
    </div>
  )
}

import clsx from 'clsx'
import { type PropsWithChildren, useContext } from 'react'
import { type NavLinkProps } from 'react-router-dom'

import { FvNavLink, Icon, type IconName } from '@fv/client-components'

import { siteContext } from '../../providers/SiteProvider'
import { ThemeSelector } from './ThemeSelector'

export const Nav = () => {
  const { logout } = useContext(siteContext)

  return (
    <nav className="navbar bg-neutral text-neutral-content fixed z-50">
      <div className="navbar-center">
        <ul className="menu menu-horizontal">
          <NavItem to="/" end icon="home">
            Admin Portal
          </NavItem>
          <NavItem to="/dispatches" icon="list-ul">
            Dispatch List
          </NavItem>
          <NavItem to="/loads" icon="truck">
            Loads
          </NavItem>
          <NavItem to="/accounts" icon="building">
            Accounts
          </NavItem>
          <NavItem to="/provisioning" icon="boxes">
            Provisioning
          </NavItem>
          <NavItem to="/reports" icon="analytics">
            Reports
          </NavItem>
          <NavItem to="/engine-reports" icon="analytics">
            Engine Reports
          </NavItem>
        </ul>
      </div>
      <div className="navbar-end mr-3 flex gap-4">
        <div>
          <ThemeSelector />
        </div>
        <div>
          <a
            className="text-sm"
            href="#"
            onClick={e => {
              e.preventDefault()
              logout()
            }}
          >
            <Icon icon="power-off" /> Logout
          </a>
        </div>
      </div>
    </nav>
  )
}

type ItemProps = PropsWithChildren<
  Pick<NavLinkProps, 'end' | 'to'> & {
    icon: IconName
  }
>

export const NavItem = ({ icon, children, ...props }: ItemProps) => {
  return (
    <li className={clsx('mr-3')}>
      <FvNavLink {...props}>
        <Icon icon={icon} />
        {children}
      </FvNavLink>
    </li>
  )
}

import { carrierUri } from '../../constants'
import { useViewAsCarrier } from '../../hooks/useViewAsCarrier'

export type ViewAsProps = {
  userId: string
  redirect?: string
}

export const ViewAsLink = (props: ViewAsProps) => {
  const viewAsCarrier = useViewAsCarrier()

  return (
    <a
      className="link link-primary"
      href="#"
      onClick={e => {
        e.preventDefault()
        viewAsCarrier.mutateAsync(props.userId).then(({ token }) => {
          let uri = `${carrierUri}/login/${token}`

          if (props.redirect) {
            uri += `?redirect=${encodeURIComponent(props.redirect)}`
          }

          window.open(uri, 'view-as-carrier-web')
        })
      }}
    >
      View As
    </a>
  )
}

import PropTypes from 'prop-types'

import UserRow from './UserRow'

const UserResults = ({ carriers, revokeSession, users }) => {
  if (!users?.length) return null

  return (
    <table className="table-zebra-zebra table">
      <thead>
        <tr>
          <th>Carrier</th>
          <th>User</th>
          <th>Accounts</th>
          <th>Sessions</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users.map((user, ux) => (
          <UserRow
            key={ux}
            user={user}
            carriers={carriers}
            revokeSession={revokeSession}
          />
        ))}
      </tbody>
    </table>
  )
}

UserResults.propTypes = {
  carriers: PropTypes.array.isRequired,
  revokeSession: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
}

export default UserResults

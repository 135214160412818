import { useState } from 'react'
import { useToggle } from 'usehooks-ts'

import { AdminSliderPanel } from '../../components/AdminSliderPanel'
import { AdminButton } from '../../components/shared/AdminButton'
import { AdminCard } from '../../components/shared/AdminCard'
import { useDeleteNotification } from './mutations'
import { NotificationForm } from './NotificationForm'
import { NotificationList } from './NotificationList'
import { type NotificationFormModel } from './types'

export const NotificationsCard = () => {
  const [sliderOpen, toggleSliderOpen] = useToggle()
  const [editModel, setEditModel] = useState<NotificationFormModel>()
  const deleteNotificaiton = useDeleteNotification()

  const handleEdit = (note: NotificationFormModel) => {
    setEditModel(note)
    toggleSliderOpen()
  }
  const handleRemove = async (note: NotificationFormModel) => {
    if (!window.confirm('Are you sure you want to delete this notification?')) {
      return
    }
    await deleteNotificaiton.mutateAsync(note._id)
  }
  const handleClose = () => {
    setEditModel(undefined)
    toggleSliderOpen()
  }
  return (
    <AdminCard title="Notifications">
      <div>
        <AdminButton className="btn-sm" icon="plus" onClick={toggleSliderOpen}>
          Add notification
        </AdminButton>
      </div>
      <NotificationList onEdit={handleEdit} onRemove={handleRemove} />
      <AdminSliderPanel
        isOpen={sliderOpen}
        closePanel={handleClose}
        title="Create notification"
      >
        {sliderOpen && ( // force remount
          <NotificationForm initialValue={editModel} onClose={handleClose} />
        )}
      </AdminSliderPanel>
    </AdminCard>
  )
}

import clsx from 'clsx'

const CarrierRow = ({ carrier }) => (
  <tr>
    <td>
      {carrier?.name || '--'}{' '}
      {carrier.code && (
        <span className="badge badge-outline badge-sm">{carrier?.code}</span>
      )}
    </td>
    <td>{carrier._id}</td>
    <td>
      <span
        className={clsx('badge badge-outline', {
          'badge-accent': carrier.status === 'active',
          'badge-ghost': carrier.status === 'hidden',
          'badge-warning': carrier.status === 'pending',
        })}
      >
        {carrier.status}
      </span>
    </td>
  </tr>
)

export default CarrierRow

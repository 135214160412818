import React from 'react'

import { Icon } from '@fv/client-components'
import { logoutSession } from '@fv/client-core'

import { apiUri } from '../../constants'

const containerStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1.5rem',
  paddingTop: '2rem',
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
      errorMessage: null,
    }
  }

  static getDerivedStateFromError(error) {
    console.error(error.message)

    return {
      hasError: true,
      errorMessage: error.message,
    }
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo?.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <nav className="navbar navbar-expand-md navbar-dark bg-dark text-light">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <Icon icon="home" /> Admin Portal
                </a>
              </li>
            </ul>
            <ul className="navbar-nav mr-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    logoutSession(null, apiUri)
                  }}
                >
                  <Icon icon="power-off" /> Logout
                </a>
              </li>
            </ul>
          </nav>

          <main role="main" style={containerStyle}>
            <Icon icon="exclamation-triangle" size="2x" />
            <br />
            <span>
              {this.state.errorMessage ?? 'Something broke, Slack a dev.'}
            </span>
            <br />
            <a href="/" className="btn">
              <Icon icon="home" /> <span>Return home</span>
            </a>
          </main>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

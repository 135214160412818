import { useEffect, useRef, useState } from 'react'

import { TextField, ValidatedForm } from '@fv/client-components'

import { AdminToggle } from '../../components/shared/AdminToggle'

const SearchForm = ({ errors, fields, setFields }) => {
  const [query, setQuery] = useState(() => fields.search)
  const formRef = useRef(null)

  useEffect(() => {
    if (query) return
    formRef.current?.reset()
  }, [query])

  return (
    <ValidatedForm
      ref={formRef}
      onValidSubmit={() => {
        setFields(prev => ({
          ...prev,
          search: query,
        }))
      }}
      className="flex gap-x-3"
    >
      <div className="join w-2/3">
        <TextField
          className="input input-primary join-item w-full"
          error={errors.search}
          maxLength={200}
          name="search"
          onChange={e => setQuery(e.target.value)}
          placeholder="any regular expression..."
          required
          value={query}
        />
        <button type="submit" className="btn btn-primary join-item">
          Search
        </button>
      </div>
      <AdminToggle
        checked={fields.whole_word}
        id="customSwitch1"
        name="wholeWord"
        onChange={e => {
          setFields(prev => ({
            ...prev,
            whole_word: e.target.checked,
          }))
        }}
        label="Match whole word?"
      />
    </ValidatedForm>
  )
}

export default SearchForm

import { Link } from 'react-router-dom'

const NotFoundPage = () => (
  <main role="main" className="container">
    <h1>Page not found</h1>
    <p className="lead">
      The page you're looking for doesn't exist.{' '}
      <Link to="/">Try from here</Link>
    </p>
  </main>
)

export default NotFoundPage

import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { useFvNavigate } from '@fv/client-components'

import { adminFetch } from '../utils'

export function useFetchUser(id?: string) {
  return useQuery<{
    profile: { firstName: string; lastName: string }
    email: string
    _id: string
  }>(['users', id], () => adminFetch(`/users/${id}`), {
    enabled: !!id,
    onError(e: any) {
      toast.error(e.message || 'Error fetching user')
    },
  })
}

export function useFetchUsers(searchFields: any) {
  const { search, whole_word } = searchFields
  const navigate = useFvNavigate()

  return useQuery(
    ['users', search, whole_word],
    () =>
      adminFetch('/users', {
        query: searchFields,
      }),
    {
      enabled: !!search,
      onSettled: () => {
        navigate('/', { replace: true })
      },
    },
  )
}

type GenericUser = {
  firstName?: string
  lastName?: string
  email: string
  _id: string
}
export function useFetchGenericUser(id: string | undefined) {
  return useQuery(
    ['generic-user', id],
    () => adminFetch<GenericUser>(`/users/generic/${id}`),
    {
      enabled: !!id,
    },
  )
}

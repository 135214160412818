import clsx from 'clsx'
import { type ForwardedRef, forwardRef, type ReactNode } from 'react'
import { useToggle } from 'usehooks-ts'

import { TextField, type TextFieldProps } from '@fv/client-components'

import { AdminButton } from './AdminButton'

type Props = TextFieldProps & {
  setValue?: (v: string) => void
  before?: ReactNode
  after?: ReactNode
}

export const AdminTextField = forwardRef(
  (
    { className, before, after, setValue, ...props }: Props,
    ref?: ForwardedRef<HTMLInputElement | null>,
  ) => {
    return (
      <label
        className={clsx(
          'input input-bordered flex w-full items-center',
          className,
        )}
      >
        {before}
        <TextField
          ref={ref}
          className="grow"
          onChange={e => {
            setValue?.(e.target.value)
            props.onChange?.(e)
          }}
          {...props}
        />
        {after}
      </label>
    )
  },
)

export const AdminPasswordField = forwardRef(
  (props: Props, ref?: ForwardedRef<HTMLInputElement | null>) => {
    const [show, toggleShow] = useToggle()
    return (
      <AdminTextField
        type={show ? 'text' : 'password'}
        {...props}
        ref={ref}
        after={
          <div className="-mr-4">
            <AdminButton
              className="btn-ghost hover:opacity-1 hover:bg-transparent"
              noOutline
              icon="eye"
              onClick={toggleShow}
            />
          </div>
        }
      />
    )
  },
)

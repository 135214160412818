import CarrierRow from './CarrierRow'

const CarrierResults = ({ carriers }) => {
  if (!carriers?.length) return null

  return (
    <table className="table-zebra table">
      <thead>
        <tr>
          <th>Carrier</th>
          <th>Carrier Id</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {carriers.map((carrier, ux) => (
          <CarrierRow key={ux} carrier={carrier} />
        ))}
      </tbody>
    </table>
  )
}

export default CarrierResults

const Thanks = () => (
  <>
    <h1>Check your email!</h1>
    <p className="lead">
      Thank you! If your address has been registered in our system by a shipper,
      we will send you an email containing further instructions.
    </p>
  </>
)

export default Thanks
